import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import Root from './Root';

const Reports = React.lazy(() => import('../pages/reports'));
const ReportsFarm = React.lazy(() => import('../pages/farms/reports'));

const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const ChangePasswordForget = React.lazy(() => import('../pages/auth/ChangePasswordForget'));
const ChangeFirstPassword = React.lazy(() => import('../pages/auth/FirstAccessChangePassword'));

const Dashboard = React.lazy(() => import('../pages/dashboard'));

const Calendars = React.lazy(() => import('../pages/calendars'));
const AllCalendars = React.lazy(() => import('../pages/all-calendars'));

const Error404 = React.lazy(() => import('../pages/error/Error404'));
const Error404Two = React.lazy(() => import('../pages/error/Error404Two'));
const Error500 = React.lazy(() => import('../pages/error/Error500'));
const Error500Two = React.lazy(() => import('../pages/error/Error500Two'));

const Positions = React.lazy(() => import('../pages/positions'));
const EditPositions = React.lazy(() => import('../pages/positions/edit'));
const CreatePositions = React.lazy(() => import('../pages/positions/create'));

const Animals = React.lazy(() => import('../pages/animals'));
const EditAnimals = React.lazy(() => import('../pages/animals/edit'));
const CreateAnimals = React.lazy(() => import('../pages/animals/create'));

const Classes = React.lazy(() => import('../pages/classes'));
const EditClasses = React.lazy(() => import('../pages/classes/edit'));
const CreateClasses = React.lazy(() => import('../pages/classes/create'));

const TipoCorte = React.lazy(() => import('../pages/tipo-corte'));
const EditTipoCorte = React.lazy(() => import('../pages/tipo-corte/edit'));
const CreateTipoCorte = React.lazy(() => import('../pages/tipo-corte/create'));

const Products = React.lazy(() => import('../pages/products'));
const EditProducts = React.lazy(() => import('../pages/products/edit'));
const CreateProducts = React.lazy(() => import('../pages/products/create'));

const Farm = React.lazy(() => import('../pages/farms/farm'));
const Farms = React.lazy(() => import('../pages/farms'));
const CreateFarms = React.lazy(() => import('../pages/farms/create'));
const Imports = React.lazy(() => import('../pages/farms/imports'));

const EditProtocols = React.lazy(() => import('../pages/protocols/edit'));
const Protocols = React.lazy(() => import('../pages/protocols'));
const CreateProtocols = React.lazy(() => import('../pages/protocols/create'));

const Users = React.lazy(() => import('../pages/users'));
const Profile = React.lazy(() => import('../pages/users/profile'));
const EditUsers = React.lazy(() => import('../pages//users/edit'));
const CreateUsers = React.lazy(() => import('../pages/users/create'));

const Manejos = React.lazy(() => import('../pages/manejos'));
const EditManejos = React.lazy(() => import('../pages/manejos/edit'));
const CreateManejos = React.lazy(() => import('../pages/manejos/create'));

const Documents = React.lazy(() => import('../pages/documents'));
const EditDocuments = React.lazy(() => import('../pages/documents/edit'));
const CreateDocuments = React.lazy(() => import('../pages/documents/create'))

const Territories = React.lazy(() => import('../pages/territories'));
const EditTerritories = React.lazy(() => import('../pages/territories/edit'));
const CreateTerritories = React.lazy(() => import('../pages/territories/create'))

export interface RoutesProps {
  path: RouteProps['path'];
  name?: string;
  component?: RouteProps['component'];
  route?: any;
  exact?: RouteProps['exact'];
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

const rootRoute: RoutesProps = {
  path: '/',
  exact: true,
  component: () => <Root />,
  route: Route,
};

const dashboardRoutes: RoutesProps = {
  path: '/dashboard',
  name: 'Dashboards',
  icon: 'airplay',
  header: 'Navigation',
  children: [
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      route: PrivateRoute,
    }
  ],
};

const uiRoutes = {
  path: '/ui',
  name: 'Components',
  icon: 'pocket',
  header: 'UI Elements',
  children: [
    {
      path: '/ui/forms',
      name: 'Forms',
      children: [
        {
          path: '/all-calendarios',
          name: 'Form Validation',
          component: AllCalendars,
          route: PrivateRoute,
        },
        {
          path: '/calendarios',
          name: 'Form Validation',
          component: Calendars,
          route: PrivateRoute,
        },
        {
          path: '/fazendas/cadastro',
          name: 'Form Validation',
          component: CreateFarms,
          route: PrivateRoute,
        },
        {
          path: '/fazendas-importacoes',
          name: 'Form Validation',
          component: Imports,
          route: PrivateRoute,
        },
        {
          path: '/fazendas/show',
          name: 'Form Advanced',
          component: Farm,
          route: PrivateRoute,
        },
        {
          path: '/fazendas',
          name: 'Form Advanced',
          component: Farms,
          route: PrivateRoute,
        },
        {
          path: '/protocolos/cadastro',
          name: 'Form Advanced',
          component: CreateProtocols,
          route: PrivateRoute,
        },
        {
          path: '/protocolos/editar',
          name: 'Form Validation',
          component: EditProtocols,
          route: PrivateRoute,
        },
        {
          path: '/protocolos',
          name: 'Form Validation',
          component: Protocols,
          route: PrivateRoute,
        },
        {
          path: '/relatorios/fazenda',
          name: 'Form Advanced',
          component: ReportsFarm,
          route: PrivateRoute,
        },
        {
          path: '/relatorios',
          name: 'Form Advanced',
          component: Reports,
          route: PrivateRoute,
        },
        {
          path: '/manejos/editar',
          name: 'Form Advanced',
          component: EditManejos,
          route: PrivateRoute,
        },
        {
          path: '/manejos/cadastro',
          name: 'Form Advanced',
          component: CreateManejos,
          route: PrivateRoute,
        },
        {
          path: '/manejos',
          name: 'Form Advanced',
          component: Manejos,
          route: PrivateRoute,
        },
        {
          path: '/cargos/editar',
          name: 'Form Advanced',
          component: EditPositions,
          route: PrivateRoute,
        },
        {
          path: '/cargos/cadastro',
          name: 'Form Advanced',
          component: CreatePositions,
          route: PrivateRoute,
        },
        {
          path: '/cargos',
          name: 'Form Advanced',
          component: Positions,
          route: PrivateRoute,
        },
        {
          path: '/animais/editar',
          name: 'Form Advanced',
          component: EditAnimals,
          route: PrivateRoute,
        },
        {
          path: '/animais/cadastro',
          name: 'Form Advanced',
          component: CreateAnimals,
          route: PrivateRoute,
        },
        {
          path: '/animais',
          name: 'Form Advanced',
          component: Animals,
          route: PrivateRoute,
        },
        {
          path: '/classes/editar',
          name: 'Form Advanced',
          component: EditClasses,
          route: PrivateRoute,
        },
        {
          path: '/classes/cadastro',
          name: 'Form Advanced',
          component: CreateClasses,
          route: PrivateRoute,
        },
        {
          path: '/classes',
          name: 'Form Advanced',
          component: Classes,
          route: PrivateRoute,
        },
        {
          path: '/tipo-corte/editar',
          name: 'Form Advanced',
          component: EditTipoCorte,
          route: PrivateRoute,
        },
        {
          path: '/tipo-corte/cadastro',
          name: 'Form Advanced',
          component: CreateTipoCorte,
          route: PrivateRoute,
        },
        {
          path: '/tipo-corte',
          name: 'Form Advanced',
          component: TipoCorte,
          route: PrivateRoute,
        },
        {
          path: '/documentos/editar',
          name: 'Form Advanced',
          component: EditDocuments,
          route: PrivateRoute,
        },
        {
          path: '/documentos/cadastro',
          name: 'Form Advanced',
          component: CreateDocuments,
          route: PrivateRoute,
        },
        {
          path: '/documentos',
          name: 'Form Advanced',
          component: Documents,
          route: PrivateRoute,
        },
        {
          path: '/territorios/editar',
          component: EditTerritories,
          route: PrivateRoute,
        },
        {
          path: '/territorios/cadastro',
          component: CreateTerritories,
          route: PrivateRoute,
        },
        {
          path: '/territorios',
          component: Territories,
          route: PrivateRoute,
        },

        {
          path: '/meus-dados',
          name: 'Form Advanced',
          component: Profile,
          route: PrivateRoute,
        },
        {
          path: '/usuarios/editar',
          name: 'Form Advanced',
          component: EditUsers,
          route: PrivateRoute,
        },
        {
          path: '/usuarios/cadastro',
          name: 'Form Advanced',
          component: CreateUsers,
          route: PrivateRoute,
        },
        {
          path: '/usuarios',
          name: 'Form Advanced',
          component: Users,
          route: PrivateRoute,
        },
        {
          path: '/produtos/editar',
          name: 'Form Advanced',
          component: EditProducts,
          route: PrivateRoute,
        },
        {
          path: '/produtos/cadastro',
          name: 'Form Advanced',
          component: CreateProducts,
          route: PrivateRoute,
        },
        {
          path: '/produtos',
          name: 'Form Advanced',
          component: Products,
          route: PrivateRoute,
        },

      ],
    },
  ],
};


const authRoutes: RoutesProps[] = [
  {
    path: '/auth/login',
    name: 'Login',
    component: Login,
    route: Route,
  },
  {
    path: '/auth/forget-password',
    name: 'Forget Password',
    component: ForgetPassword,
    route: Route,
  },
  {
    path: '/auth/change-forget-password/:uuid',
    name: 'Forget Password',
    component: ChangePasswordForget,
    route: Route,
  },
  {
    path: '/auth/first-access',
    name: 'Forget Password',
    component: ChangeFirstPassword,
    route: Route,
  },
  {
    path: '/auth/logout',
    name: 'Logout',
    component: Logout,
    route: Route,
  }
];

const otherPublicRoutes = [
  {
    path: '/error-404',
    name: 'Error - 404',
    component: Error404,
    route: Route,
  },
  {
    path: '/error-404-two',
    name: 'Error - 404 Two',
    component: Error404Two,
    route: Route,
  },
  {
    path: '/error-500',
    name: 'Error - 500',
    component: Error500,
    route: Route,
  },
  {
    path: '/error-500-two',
    name: 'Error - 500 Two',
    component: Error500Two,
    route: Route,
  },
];

const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const authProtectedRoutes = [rootRoute, dashboardRoutes, uiRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
