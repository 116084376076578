const config = {
  API_URL: window.location.host.includes("localhost")
    ? "http://localhost:3011/api/v1"
    : window.location.host.includes("64.225") || window.location.host.includes("sandbox.calendariosanitariobovino.com.br")
    ? "https://api.sandbox.calendariosanitariobovino.com.br/api/v1"
    : "https://api.calendariosanitariobovino.com.br/api/v1",
  // API_URL: "http://64.225.18.241:3011/api/v1",
};

export default config;
