import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';

import { APICore } from '../helpers/api/apiCore';
import { RootState } from '../redux/store';

interface PrivateRouteProps {
   component: React.FunctionComponent<RouteProps>;
   roles?: string;
}

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }: PrivateRouteProps) => {
   const api = new APICore();

   const { user } = useSelector((state: RootState) => ({
      user: state.Auth.user,
   }));

   return (
      <Route
         {...rest}
         render={(props: RouteComponentProps) => {
            if (api.isUserAuthenticated() && user?.firstAccess) {
               return (
                  <Redirect
                     to={{
                        pathname: '/auth/first-access',
                        state: { from: props['location'] },
                     }}
                  />
               );
            }
            if (api.isUserAuthenticated() === false) {
               return (
                  <Redirect
                     to={{
                        pathname: '/auth/login',
                        state: { from: props['location'] },
                     }}
                  />
               );
            }

            const loggedInUser = api.getLoggedInUser();

            if (roles && roles.indexOf(loggedInUser.role) === -1) {
               return <Redirect to={{ pathname: '/' }} />;
            }
            return <Component {...props} />;
         }}
      />
   );
};

export default PrivateRoute;
